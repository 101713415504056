@import '../../style/themes/index';
@import '../../style/mixins/index';

@anchor-border-width: 2px;

.@{ant-prefix}-anchor {
  .reset-component();

  position: relative;
  padding-left: @anchor-border-width;

  &-wrapper {
    margin-left: -4px;
    padding-left: 4px;
    overflow: auto;
    background-color: @anchor-bg;
  }

  &-ink {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    &::before {
      position: relative;
      display: block;
      width: @anchor-border-width;
      height: 100%;
      margin: 0 auto;
      background-color: @anchor-border-color;
      content: ' ';
    }
    &-ball {
      position: absolute;
      left: 50%;
      display: none;
      width: 8px;
      height: 8px;
      background-color: @component-background;
      border: 2px solid @primary-color;
      border-radius: 8px;
      transform: translateX(-50%);
      transition: top 0.3s ease-in-out;
      &.visible {
        display: inline-block;
      }
    }
  }

  &.fixed &-ink &-ink-ball {
    display: none;
  }

  &-link {
    padding: @anchor-link-padding;
    line-height: 1.143;

    &-title {
      position: relative;
      display: block;
      margin-bottom: 6px;
      overflow: hidden;
      color: @text-color;
      white-space: nowrap;
      text-overflow: ellipsis;
      transition: all 0.3s;

      &:only-child {
        margin-bottom: 0;
      }
    }

    &-active > &-title {
      color: @primary-color;
    }
  }

  &-link &-link {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

@import './rtl';

@root-entry-name: variable;